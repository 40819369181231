import { template as template_5c4ab98ca74c4ed2ac18e960856fa501 } from "@ember/template-compiler";
const FKText = template_5c4ab98ca74c4ed2ac18e960856fa501(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
