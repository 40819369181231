import { template as template_bdb78197fdfc4a55a6f15e62f6f0432f } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_bdb78197fdfc4a55a6f15e62f6f0432f(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
