import { template as template_a76e64e4b42c4545b42e4e99cf0a543e } from "@ember/template-compiler";
const FKLabel = template_a76e64e4b42c4545b42e4e99cf0a543e(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
