import { template as template_1e1b73d367034548875d4f587a489df7 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_1e1b73d367034548875d4f587a489df7(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
